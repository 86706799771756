<template>
  <v-tooltip bottom v-if="tooltipText">
    <template v-slot:activator="{ on }">
      <v-btn icon @click="$emit('click')" v-on="on">
        <v-icon size="18">far fa-pen</v-icon>
      </v-btn>
    </template>
    <span class="text-label">{{ tooltipText }}</span>
  </v-tooltip>

  <v-btn v-else icon @click="$emit('click')">
    <v-icon size="18">far fa-pen</v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: "BtnIconEdit",
    props: {
      tooltipText: {type: String, default: null}
    }
  }
</script>

<style scoped>

</style>
